<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card class="pa-5">
      <v-card-text>
        <template
          v-if="item.type === 'category'"
        >
          <CategorySelector
            v-model="internalId"
            :multiple="false"
          />
        </template>
        <template
          v-if="item.type === 'taxonomy_kind'"
        >
          <TaxonomyKindSelector
            v-model="internalId"
            :multiple="false"
          />
        </template>
        <template
          v-else-if="item.type === 'taxonomy'"
        >
          <v-row>
            <v-col>
              <TaxonomyAttributeSelector
                v-model="selectedTaxonomyAttribute"
                :multiple="false"
              />
            </v-col>
            <v-col>
              <TaxonomySelector
                v-if="selectedTaxonomyAttribute"
                v-model="internalId"
                :multiple="false"
                :attribute="selectedTaxonomyAttribute"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CategorySelector from '@/components/base/Product/Input/CategorySelector.vue'
import { mapState } from 'vuex'
import TaxonomyAttributeSelector from '@/components/base/Product/Input/TaxonomyAttributeSelector.vue'
import TaxonomyKindSelector from '@/components/base/Product/Input/TaxonomyKindSelector.vue'
import TaxonomySelector from '@/components/base/Product/Input/TaxonomySelector.vue'

export default {
  name: 'SelectionModal',
  components: { TaxonomyAttributeSelector, TaxonomyKindSelector, TaxonomySelector, CategorySelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      selectedTaxonomyAttribute: null,
      selectedInternalId: null,
    }
  },
  computed: {
    ...mapState(['categories', 'taxonomies']),
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
    internalId: {
      get () { return this.item.internal_id  },
      set (value) { this.selectedInternalId = value },
    },
  },
  watch: {
    item () {
      this.initItem()
    },
  },
  created() {
    this.initItem()
  },
  methods: {
    initItem () {
      if (this.item.type !== 'taxonomy') {
        return
      }
      Object.values(this.taxonomies).forEach(
        taxonomies => {
          const matchTaxonomy = taxonomies.find(taxonomy => taxonomy.id === this.item.internal_id)
          if (matchTaxonomy) {
            this.selectedTaxonomyAttribute = matchTaxonomy.attribute.id
          }
        }
      )
    },
    close() {
      this.openedValue = false
      this.selectedTaxonomyAttribute = null
      this.internalId = null
    },
    submit() {
      this.$emit('submited', {
        id: this.item.id,
        internalId: this.selectedInternalId,
      })
      this.close()
    },
  },
}
</script>
