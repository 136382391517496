<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card class="pa-5">
      <v-card-text>
        <v-row>
          <v-col>
            <ProviderSelector v-model="providerId" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SourceSelector v-model="source" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ClassificationTypeSelector v-model="type" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="externalReference"
              label="Référence externe"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="externalName"
              label="Nom externe"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClassificationTypeSelector from '@/components/base/ExternalCatalog/Input/ClassificationTypeSelector.vue'
import ProviderSelector from '@/components/base/ExternalCatalog/Input/ProviderSelector.vue'
import SourceSelector from '@/components/base/ExternalCatalog/Input/SourceSelector.vue'

export default {
  name: 'CreationModal',
  components: { ClassificationTypeSelector, SourceSelector, ProviderSelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      providerId: null,
      source: null,
      type: null,
      externalReference: null,
      externalName: null,
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
  },

  methods: {
    close() {
      this.openedValue = false
      this.providerId = null
      this.source = null
      this.type = null
      this.externalReference = null
      this.externalName = null
    },
    submit() {
      this.$emit('submited', {
        providerId: this.providerId,
        source: this.source,
        type: this.type,
        externalReference: this.externalReference,
        externalName: this.externalName,
      })
      this.close()
    },
  },
}
</script>
