<template>
  <Page>
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="openCreationModal"
        icon="mdi-plus-circle"
        text="Ajouter"
      />
    </template>
    <template v-slot:content>
      <Filters
        :add-modal-opened.sync="modal.add.opened"
        @onFiltersChanged="onFiltersChanged"
      />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.source="{ item }">
            {{ $t('views.external_catalog.classifications.enum.sources.' + item.source) }}
          </template>
          <template v-slot:item.provider="{ item }">
            {{ item.provider?.name }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ $t('views.external_catalog.classifications.enum.types.' + item.type) }}
          </template>
          <template v-slot:item.externalReference="{ item }">
            {{ item.external_reference }}
          </template>
          <template v-slot:item.externalName="{ item }">
            {{ item.external_name }}
          </template>
          <template v-slot:item.internalName="{ item }">
            {{ item.internal_name }}
          </template>
          <template v-slot:item.action="{ item }">
            <div style="white-space: nowrap;">
              <v-btn
                icon
                @click="openSelectionModal(item)"
              >
                <v-icon
                  color="blue lighten-1"
                >
                  mdi-pencil-outline
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="duplicate(item)"
              >
                <v-icon
                  color="blue lighten-1"
                >
                  mdi-content-duplicate
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="deleteLine(item)"
              >
                <v-icon
                  color="blue lighten-1"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-sheet>
      <SelectionModal
        v-if="modal.selection.selectedItem"
        :opened.sync="modal.selection.opened"
        :item="modal.selection.selectedItem"
        @submited="saveSelection"
      />
      <CreationModal
        :opened.sync="modal.add.opened"
        @submited="add"
      />
      <Snackbar />
      <ConfirmModal ref="confirm" />
    </template>
  </Page>
</template>

<script>
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import CreationModal from '@/views/ExternalCatalog/Classifications/CreationModal.vue'
import Filters from '@/views/ExternalCatalog/Classifications/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import SelectionModal from '@/views/ExternalCatalog/Classifications/SelectionModal.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ExternalCatalogClassificationsList',
  components: { ButtonAction, Page, CreationModal, SelectionModal, Filters, Snackbar },
  mixins: [snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        source: this.$route.query.source,
        type: this.$route.query.type,
        provider: this.$route.query.provider,
      },
      modal: {
        selection: {
          opened: false,
          selectedItem: null,
        },
        add: {
          opened: false,
        },
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.source'),
            value: 'source',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.provider'),
            value: 'provider',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.type'),
            value: 'type',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.external_reference'),
            value: 'externalReference',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.external_name'),
            value: 'externalName',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.classifications.headers.internal_name'),
            value: 'internalName',
            sortable: false,
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            align: 'end',
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'external_catalog/classifications?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'ExternalCatalogClassificationsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    openCreationModal() {
      this.modal.add.opened = true
    },
    openSelectionModal(item) {
      this.modal.selection.selectedItem = item
      this.modal.selection.opened = true
    },
    async saveSelection({ id, internalId }) {
      this.modal.selection.opened = false

      this.cancel()

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.table.loading = true

      try {
        await this.$axios.post('external_catalog/classifications/' + id + '/bind', {
          internalId,
        }, {
          cancelToken: axiosSource.token,
        })
        this.snackbarSuccess('success')
        this.load()
      } catch (error) {
        this.snackbarError('error')
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
          this.table.loading = false
        }
      } finally {
        this.request = null
      }
    },

    async add(item) {
      this.table.loading = true

      this.cancel()

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      try {
        await this.$axios.post('external_catalog/classifications', {
          provider_id: item.providerId,
          type: item.type,
          source: item.source,
          external_reference: item.externalReference,
          external_name: item.externalName,
        }, {
          cancelToken: axiosSource.token,
        })

        this.snackbarSuccess('success')
        this.load()
      } catch (error) {
        this.snackbarError('error')
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
          this.table.loading = false
        }
      } finally {
        this.request = null
      }
    },
    async duplicate(item) {
      this.table.loading = true

      if (await this.$refs.confirm.open(
        'Dupplication',
        'Êtes-vous sûr de vouloir duppliquer cette ligne ?')
      ) {
        this.cancel()

        let axiosSource = this.$axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }

        try {
          await this.$axios.post('external_catalog/classifications', {
            provider_id: item.providerId,
            type: item.type,
            source: item.source,
            external_reference: item.external_reference,
            external_name: item.external_name,
          }, {
            cancelToken: axiosSource.token,
          })

          this.snackbarSuccess('success')
          this.load()
        } catch (error) {
          this.snackbarError('error')
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        } finally {
          this.request = null
        }
      } else {
        this.table.loading = false
      }
    },
    async deleteLine(item) {
      this.table.loading = true

      if (await this.$refs.confirm.open(
        'Suppression',
        'Êtes-vous sûr de vouloir supprimer cette ligne ?')
      ) {
        this.cancel()

        let axiosSource = this.$axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }

        try {
          await this.$axios.delete('external_catalog/classifications/' + item.id, {}, {
            cancelToken: axiosSource.token,
          })
          this.snackbarSuccess('success')
          this.load()
        } catch (error) {
          this.snackbarError('error')
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        } finally {
          this.request = null
        }
      } else {
        this.table.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
